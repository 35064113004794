import { mapState } from "vuex";
import EventBus from "@/event/EventBus";
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';
const queryString = require('query-string');
import moment from "moment";
import commonAct from '@/commonActionHandle.js';



// Constant
import { API_CALL_STATUS } from "@/constant/config";
import { SETTINGS_RATING_API } from "@/constant/apiSettings";

// Extend
import baseComponent from '@/scripts/baseComponent';

// Api
import api from '@/api/baseApi';

// Component 

export default {

    components: {
        RatingDetail: lazyLoadComponent({
          componentFactory: () => import("@/views/Rating/RatingDetail"),
          loading: SkeletonBox,
        }),
    },

    extends: baseComponent,
    props: {
        detailUserItem: {
            type: Object,
            default: null,
        },
        cardHeight: {
            type: String,
            default: 'height: calc(100dvh - 7rem);'
        },
        minInfo: {
            type: Boolean,
            default: false
        },
        ownedTarget: {
            type: String,
            default: null
        },
    },
    watch: {
        detailUserItem: {
            deep: true,
            handler(newVal){
                if (this.detailUserItem !== null) this.getData();
            }
        }
    },
    data() {
        return {
            listData: [],
            pagination: {
                querySearch: '',
                level: '',
                status: '',
                staffFilter: '',
                orderBy: 'A.ID',
                directionSort: 'DESC',
                pageIndex: 1,
                pageSize: 50,
                outRowsNumber: 0,
                servicePack: '',
                includeSystemUser: false
            },

            colors: ['red', '#F7BA2A', '#FF9900'],
            RatingDetailItem: null,
            isShowDialogRatingDetail: false,
        };
    },

    computed: {
        ...mapState({
            userInfo: (state) => state.auth.user,
            listStaff: state => state.staffs,
            listServicePack: state => state.common.listServicePack,
            decentralization: state => state.auth.decentralization,
        }),
    },

    created() {
        EventBus.$on("openPopup", this.openPopup);
        EventBus.$on("closePopup", this.closePopup);
        EventBus.$on("onThreadClosed", this.threadClosed);
        this.getData();
    },

    mounted() {

    },

    methods: {
        // Duration tiome accept
        diffTimeAccept(start, end) {
            let value = moment.utc(end).valueOf() - moment.utc(start).valueOf();
            if (value < 0) value = 1000;
            let second = value / 1000;
            let hrs = Math.floor(second / 3600);
            let min = Math.floor((second - hrs * 3600) / 60);
            let sec = Math.floor(second - hrs * 3600 - min * 60);
            let result = "";
            if (second >= 0) {
                if (second < 1) {
                    result = parseFloat(second).toFixed(2) + "s";
                } else {
                    result += hrs > 0 ? hrs + "h " : "";
                    result += min > 0 ? min + "m " : "";
                    result += sec > 0 ? sec + "s" : "";
                }
            } else result = "- - : - -";
            return result;
        },
        // Processing event
        processingEvent(param) {
            this[param.event](param.data);
        },

        // Open pop-up
        openPopup(param) {
            $(`#${param.type}`).modal({ backdrop: "static" });
            this.target = param.data;
        },

        // Close pop-up
        closePopup(popup) {
            $(`#${popup}`).modal("hide");
        },

        // Action
        performAction(param) {
            switch (param.action) {
                case 'refresh':
                    this.getData(this.pagination);
                    break;
                case 'refreshClearFilter':
                    this.pagination.querySearch = '';
                    this.pagination.staffFilter = '';
                    this.pagination.level = '';
                    this.pagination.status = '';
                    this.getData(this.pagination);
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
            }
        },

        // Search list
        searchList() {
            //this.pagination.status = 1;
            this.getData();
        },

        // Sort list
        sortList(element) {
            this.pagination.pageIndex = 1;
            this.pagination.orderBy = element;
            if (this.pagination.directionSort === 'ASC') {
                this.pagination.directionSort = 'DESC'
            } else {
                this.pagination.directionSort = 'ASC'
            }
            this.getData();
        },

        // Pagination size change
        handleSizeChange(pageSize) {
            this.pagination.pageIndex = 1;
            this.pagination.pageSize = pageSize;
            this.getData();
        },

        // Pagination page change
        handleCurrentChange(element) {
            this.pagination.pageIndex = element;
            this.getData();
        },

        // Get data
        getData() {
            this.feedStatusLoading(API_CALL_STATUS.LOADING);
            this.isCheckAll = false;
            if (this.detailUserItem !== null) this.pagination.userId = this.detailUserItem.id;
            api.baseRequest(SETTINGS_RATING_API.RATING_VIEW, null, `?${queryString.stringify(this.pagination)}`)
                .then((res) => {
                    try {
                        let responseData = res.data;
                        let pagingItem = res.data.pagingItem;
                        this.listData = responseData.data;
                        this.listData.forEach(element => {
                            element.columnUser = this.mapColumnUser(element);
                            try {
                                if (element.suggestionList !== null) element.suggestionListJson = JSON.parse(element.suggestionList);
                                else element.suggestionListJson = [];
                            } catch (error) {
                                console.log("getData -> error", error)
                            }
                        });
                        this.pagination.orderBy = pagingItem.orderBy;
                        this.pagination.directionSort = pagingItem.directionSort;
                        this.pagination.pageIndex = pagingItem.pageIndex;
                        this.pagination.pageSize = pagingItem.pageSize;
                        this.pagination.outRowsNumber = pagingItem.outRowsNumber;

                        this.feedStatusLoading(API_CALL_STATUS.SUCCESS, this.listData);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                    }
                })
                .catch(error => {
                    this.processCatchApiRequest(error, "Request failed ...", 5)
                });
        },
        handleCommand(command) {
            this.setStatus(command.element, command.targetStatus)
        },
        accepted(element) {
            commonAct.showConfirm(`<div class="text-left"> Take care this rating record ?</div>`, () => {
                api.baseRequest(SETTINGS_RATING_API.RATING_ACCEPT, { id: element.id }, null)
                    .then((res) => {
                        try {
                            let responseData = res.data;
                            this.$set(element, "status", responseData.status);
                            this.$set(element, "staffAccepted", responseData.staffAccepted);
                            this.$set(element, "acceptedTime", responseData.acceptedTime);
                            this.notificationSuccess("Success", "Perform action successed");
                        } catch (error) {
                            console.log("getData -> error", error);
                        }
                    })
                    .catch(error => {
                        this.processCatchApiRequest(error, "Request failed ...", 5);
                    });
            });
        },
        setStatus(element, targetStatus) {
            api.baseRequest(SETTINGS_RATING_API.RATING_UPDATE_STATUS, { id: element.id, targetStatus: targetStatus }, null)
                .then((res) => {
                    try {
                        let responseData = res.data;
                        this.$set(element, "status", targetStatus);
                        this.notificationSuccess("Success", "Perform action successed");
                    } catch (error) {
                        console.log("getData -> error", error);
                    }
                })
                .catch(error => {
                    this.processCatchApiRequest(error, "Request failed ...", 5);
                });
        },
        showDialogDetail(item) {
            this.$set(this, "RatingDetailItem", item);
            this.$set(this, "isShowDialogRatingDetail", true);
        },
        threadClosed() {
            this.$set(this, "isShowDialogRatingDetail", false);
            this.$set(this, "RatingDetailItem", null);
        }
    }
}